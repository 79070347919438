export const productData = {
    Bath: {
        subcategories: {
            Vanity: [
                { name: "Vanity1", price: 300, image: "/Products/bath/vanity/img/vanity1.png", glb: "/Products/bath/vanity/glb/vanity1_join-origin1.glb" },
                { name: "Vanity2", price: 400, image: "/Products/bath/vanity/img/vanity2.png", glb: "/Products/bath/vanity/glb/vanity2_origincursor.glb" },
                { name: "Vanity3", price: 250, image: "/Products/bath/vanity/img/vanity3.png", glb: "/Products/bath/vanity/glb/vanity3.glb" },
                { name: "Vanity4", price: 520, image: "/Products/bath/vanity/img/vanity4.png", glb: "/Products/bath/vanity/glb/vanity4.glb" },
                { name: "Vanity5", price: 1000, image: "/Products/bath/vanity/img/vanity5.png", glb: "/Products/bath/vanity/glb/vanity5.glb" },
                { name: "Vanity6", price: 900, image: "/Products/bath/vanity/img/vanity6.png", glb: "/Products/bath/vanity/glb/vanity6.glb" },
                { name: "Vanity7", price: 380, image: "/Products/bath/vanity/img/vanity7.png", glb: "/Products/bath/vanity/glb/vanity7.glb" },
                { name: "Vanity8", price: 390, image: "/Products/bath/vanity/img/vanity8.png", glb: "/Products/bath/vanity/glb/vanity8.glb" },
            ],
            Toilet: [
                { name: "Toilet1", price: 800, image: "/Products/bath/toilet/img/toilet1.png", glb: "/Products/bath/toilet/glb/toilet1.glb" },
                { name: "Toilet2", price: 1800, image: "/Products/bath/toilet/img/toilet2.png", glb: "/Products/bath/toilet/glb/toilet2.glb" },
                { name: "Toilet3", price: 2300, image: "/Products/bath/toilet/img/toilet3.png", glb: "/Products/bath/toilet/glb/toilet3.glb" },
                { name: "Toilet4", price: 1500, image: "/Products/bath/toilet/img/toilet4.png", glb: "/Products/bath/toilet/glb/toilet4.glb" },
                { name: "Toilet5", price: 700, image: "/Products/bath/toilet/img/toilet5.png", glb: "/Products/bath/toilet/glb/toilet5.glb" },
                { name: "Toilet6", price: 920, image: "/Products/bath/toilet/img/toilet6.png", glb: "/Products/bath/toilet/glb/toilet6.glb" },

            ],
            Shower: [
                { name: "Shower1", price: 5100, image: "/Products/bath/shower/img/shower1.png", glb: "/Products/bath/toilet/glb/shower1.glb" },
                { name: "Shower2", price: 3400, image: "/Products/bath/shower/img/shower2.png", glb: "/Products/bath/toilet/glb/shower2.glb" },
                { name: "Shower3", price: 2800, image: "/Products/bath/shower/img/shower3.png", glb: "/Products/bath/toilet/glb/shower3.glb" },
            ],
            Other: [
                { name: "Faucet1", price: 2100, image: "/Products/bath/other/img/faucet1.png", glb: "/Products/bath/other/glb/faucet1.glb" },
                { name: "Faucet2", price: 900, image: "/Products/bath/other/img/faucet2.png", glb: "/Products/bath/other/glb/faucet2.glb" },
                { name: "Faucet3", price: 880, image: "/Products/bath/other/img/faucet3.png", glb: "/Products/bath/other/glb/faucet3.glb" },
                { name: "Mirror1", price: 330, image: "/Products/bath/other/img/mirror.png", glb: "/Products/bath/other/glb/mirror.glb" },
                { name: "Toweldryer", price: 580, image: "/Products/bath/other/img/toweldryer.png", glb: "/Products/bath/other/glb/toweldryer.glb" },


            ],
            Bathtub: [
                { name: "Bathtub1", price: 2900, image: "/Products/bath/bathtub/img/bathtub1.png", glb: "/Products/bath/bathtub/glb/bathtub1.glb" },
                { name: "Bathtub2", price: 7700, image: "/Products/bath/bathtub/img/bathtub2.png", glb: "/Products/bath/bathtub/glb/bathtub2.glb" },
                { name: "Bathtub3", price: 8900, image: "/Products/bath/bathtub/img/bathtub3.png", glb: "/Products/bath/bathtub/glb/bathtub3.glb" },
                { name: "Bathtub4", price: 3800, image: "/Products/bath/bathtub/img/bathtub4.png", glb: "/Products/bath/bathtub/glb/bathtub4.glb" },
            ],
        },
    },

};
