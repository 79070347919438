import React, { useState, useEffect } from 'react';
import './TexturePanel.css';

const categories = {
  Tiles: [
    {
        url: './textures/Esi/surface_fabric_xi2kdjxl/xi2kdjxl_8K_Albedo.jpg',
        baseColor: './textures/Esi/surface_fabric_xi2kdjxl/xi2kdjxl_8K_Albedo.jpg',
        height:'',
        aomap: './textures/Esi/surface_fabric_xi2kdjxl/xi2kdjxl_8K_AO.jpg',
        metallic:'',
        normalmap: './textures/Esi/surface_fabric_xi2kdjxl/xi2kdjxl_8K_Normal.jpg',
        roughness: './textures/Esi/surface_fabric_xi2kdjxl/xi2kdjxl_8K_Roughness.jpg',
        displacement: './textures/Esi/surface_fabric_xi2kdjxl/xi2kdjxl_8K_Displacement.jpg',
        name: 'Fabric'
      },
    {
        url: './textures/Esi/other_various_umonbbqdy/umonbbqdy_2K_Albedo.jpg',
        baseColor: './textures/Esi/other_various_umonbbqdy/umonbbqdy_2K_Albedo.jpg',
        height:'',
        aomap: './textures/Esi/other_various_umonbbqdy/umonbbqdy_2K_AO.jpg',
        metallic:'',
        normalmap: './textures/Esi/other_various_umonbbqdy/umonbbqdy_2K_Normal.jpg',
        roughness: './textures/Esi/other_various_umonbbqdy/umonbbqdy_2K_Roughness.jpg',
        displacement: './textures/Esi/other_various_umonbbqdy/umonbbqdy_2K_Displacement.jpg',
        name: 'Fabric'
      },
    {
        url: './textures/Esi/surface_fabric_xi2kehol/Thumbs/2k/xi2kehol_2K_Albedo.jpg',
        baseColor: './textures/Esi/surface_fabric_xi2kehol/Thumbs/2k/xi2kehol_2K_Albedo.jpg',
        height:'',
        aomap: './textures/Esi/surface_fabric_xi2kehol/Thumbs/2k/xi2kehol_2K_AO.jpg',
        metallic:'',
        normalmap: './textures/Esi/surface_fabric_xi2kehol/Thumbs/2k/xi2kehol_2K_Normal.jpg',
        roughness: './textures/Esi/surface_fabric_xi2kehol/Thumbs/2k/xi2kehol_2K_Roughness.jpg',
        displacement: './textures/Esi/surface_fabric_xi2kehol/Thumbs/2k/xi2kehol_2K_Displacement.jpg',
        name: 'Fabric'
      },
    {
        url: './textures/Esi/fabric_leather_tlooadar/Thumbs/2k/tlooadar_2K_Albedo.jpg',
        baseColor: './textures/Esi/fabric_leather_tlooadar/Thumbs/2k/tlooadar_2K_Albedo.jpg',
        height:'',
        aomap: './textures/Esi/fabric_leather_tlooadar/Thumbs/2k/tlooadar_2K_AO.jpg',
        metallic:'',
        normalmap: './textures/Esi/fabric_leather_tlooadar/Thumbs/2k/tlooadar_2K_Normal.jpg',
        roughness: './textures/Esi/fabric_leather_tlooadar/Thumbs/2k/tlooadar_2K_Roughness.jpg',
        displacement: './textures/Esi/fabric_leather_tlooadar/Thumbs/2k/tlooadar_2K_Displacement.jpg',
        name: 'Fabric'
      },
    {
        url: './textures/Esi/fabric_leather_oi2veqp0/oi2veqp_2K_Albedo.jpg',
        baseColor: './textures/Esi/fabric_leather_oi2veqp0/oi2veqp_2K_Albedo.jpg',
        height:'',
        aomap: '',
        metallic:'',
        normalmap: '',
        roughness: '',
        displacement: '',
        name: 'Fabric'
      },
   

  
    
    // اضافه کردن سایر تکسچرها مشابه بالا
  ]
  // دسته‌بندی‌های دیگر مثل Marbels و Objects هم می‌توانند در اینجا اضافه شوند
};

const TexturePanel = ({ onTextureSelect }) => {
  const [textures, setTextures] = useState([]);

  useEffect(() => {
    // بارگذاری دسته‌بندی‌ها از فایل JSON یا API
    const loadTextures = () => {
      // برای نمونه از دسته‌بندی Tiles استفاده می‌کنیم
      setTextures(categories.Tiles);
    };
    loadTextures();
  }, []);

    const handleClick = (textureData) => {
      // ارسال داده‌های تکسچر به کنواس سه‌بعدی
      onTextureSelect(textureData);
    };

  return (
    <div className="texture-panel">
      <div className="texture-row">
        {textures.map((texture, index) => (
          <div key={index} className="texture-item" onClick={() => handleClick(texture)}>
            <img src={texture.url} alt={texture.name} className="texture-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TexturePanel;
